export interface TierPackage {
	id: string;
	name: string;
	tier: 'gold' | 'silver' | 'bronze' | 'upgrade_bronze_to_silver' | 'upgrade_bronze_to_gold' | 'upgrade_silver_to_gold' | 'one_time_pay_test' | 'grandmaster' | 'master' | 'expert' | 'upgrade_expert_to_master' | 'upgrade_master_to_grandmaster' | 'upgrade_expert_to_grandmaster';
	course_ids: string[];
  }

  export const tierPackageIds: TierPackage[] = [
	{ id: "prod_PnZ9OixQFy8c6m", name: "Grandmaster", tier: "gold" , course_ids: ["717d0592-90f0-4563-9e33-bde2a8a6302f", "fdee947e-19d1-41ef-88e0-afd0e665bc69"]},
	{ id: "prod_PnZH9fc6oZl5V9", name: "Master", tier: "silver", course_ids: ["fdee947e-19d1-41ef-88e0-afd0e665bc69", "717d0592-90f0-4563-9e33-bde2a8a6302f"] },
	{ id: "prod_PnZM5ETN3s8bhn", name: "Bronze Tier", tier: "bronze", course_ids: ["fdee947e-19d1-41ef-88e0-afd0e665bc69"] },
	{ id: "prod_QMfaGsWd1YHtUJ", name: "Upgrade From Bronze to Master Plan" ,tier: "upgrade_bronze_to_silver", course_ids: ["fdee947e-19d1-41ef-88e0-afd0e665bc69", "717d0592-90f0-4563-9e33-bde2a8a6302f"]},
	{ id: "prod_QMfdFlVPVId5n4", name: "Upgrade from Bronze to Grandmaster" ,tier: "upgrade_bronze_to_gold", course_ids: ["717d0592-90f0-4563-9e33-bde2a8a6302f", "fdee947e-19d1-41ef-88e0-afd0e665bc69"]},
	{ id: "prod_QMfkpdcsQYcvUG", name: "Upgrade from Master to Grandmaster" ,tier: "upgrade_silver_to_gold", course_ids: ["717d0592-90f0-4563-9e33-bde2a8a6302f"]},
	{ id: "prod_Q14n8pEtGecNUR", name: "One Time Pay Test" ,tier: "one_time_pay_test", course_ids: ["717d0592-90f0-4563-9e33-bde2a8a6302f", "fdee947e-19d1-41ef-88e0-afd0e665bc69"]},
	{ id: "424", name: "Grandmaster", tier: "grandmaster" , course_ids: ["717d0592-90f0-4563-9e33-bde2a8a6302f", "fdee947e-19d1-41ef-88e0-afd0e665bc69"]},
	{ id: "423", name: "Master", tier: "master" , course_ids: ["717d0592-90f0-4563-9e33-bde2a8a6302f", "fdee947e-19d1-41ef-88e0-afd0e665bc69"]},
	{ id: "299", name: "Expert", tier: "expert" , course_ids: ["fdee947e-19d1-41ef-88e0-afd0e665bc69"]},
	{ id: "3138", name: "Upgrade from Expert to Master", tier: "upgrade_expert_to_master" , course_ids: ["717d0592-90f0-4563-9e33-bde2a8a6302f", "fdee947e-19d1-41ef-88e0-afd0e665bc69"]},
	{ id: "3139", name: "Upgrade from Master to Grandmaster", tier: "upgrade_master_to_grandmaster" , course_ids: ["717d0592-90f0-4563-9e33-bde2a8a6302f", "fdee947e-19d1-41ef-88e0-afd0e665bc69"]},
	{ id: "3141", name: "Upgrade from Expert to Grandmaster", tier: "upgrade_expert_to_grandmaster" , course_ids: ["717d0592-90f0-4563-9e33-bde2a8a6302f", "fdee947e-19d1-41ef-88e0-afd0e665bc69"]},
	
];