import React, { useState, useEffect } from 'react';
interface CountdownProps {
  endDate: string | Date;
  message: string;
}
const Countdown: React.FC<CountdownProps> = ({
  endDate,
  message: countdownMessage
}) => {
  const [timeLeft, setTimeLeft] = useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const [message, setMessage] = useState<string>('');
  const [isFinished, setIsFinished] = useState<boolean>(false);
  useEffect(() => {
    try {
      const endDateTime = new Date(endDate);

      // Validate that we have a valid date
      if (isNaN(endDateTime.getTime())) {
        console.error('Invalid end date provided to Countdown component');
        setIsFinished(true);
        return;
      }
      const timer = setInterval(() => {
        const now = new Date();
        const difference = endDateTime.getTime() - now.getTime();
        if (difference > 0) {
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor(difference / (1000 * 60 * 60) % 24);
          const minutes = Math.floor(difference / 1000 / 60 % 60);
          const seconds = Math.floor(difference / 1000 % 60);
          setTimeLeft({
            days,
            hours,
            minutes,
            seconds
          });
          setMessage(countdownMessage);
        } else {
          clearInterval(timer);
          setIsFinished(true);
        }
      }, 1000);
      return () => clearInterval(timer);
    } catch (error) {
      console.error('Error in Countdown component:', error);
      setIsFinished(true);
    }
  }, [endDate, countdownMessage]);
  if (isFinished) {
    return null;
  }
  return <div className="w-full text-oxfordBlue py-4 sm:py-8 px-2 sm:px-4" data-sentry-component="Countdown" data-sentry-source-file="countdown.tsx">
			<div className="container mx-auto text-center">
				<h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-red-600 animate-pulse whitespace-pre-line">{message}</h2>
				<div className="flex justify-center items-center space-x-2 sm:space-x-6">
					{[{
          value: timeLeft.days,
          label: 'Days'
        }, {
          value: timeLeft.hours,
          label: 'Hours'
        }, {
          value: timeLeft.minutes,
          label: 'Minutes'
        }, {
          value: timeLeft.seconds,
          label: 'Seconds'
        }].map((item, index) => <React.Fragment key={item.label}>
							<div className="flex flex-col items-center">
								<span className="text-3xl sm:text-5xl font-bold text-red-700 transition-all duration-300 ease-in-out transform hover:scale-110">
									{item.value.toString().padStart(2, '0')}
								</span>
								<span className="text-xs sm:text-sm uppercase mt-1 sm:mt-2 font-semibold">{item.label}</span>
							</div>
							{index < 3 && <div className="text-2xl sm:text-4xl font-bold text-red-400">:</div>}
						</React.Fragment>)}
				</div>
			</div>
		</div>;
};
export default Countdown;